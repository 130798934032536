<template>
  <div class="card" @click="handleClick()">
    <img :src="thumbnail" class="mb-n2 m-3 rounded-3 img-fluid" />
    <div class="card-body">
      <h5 class="card-title">{{ title }}</h5>
      <div class="card-text mt-2">
        <div class="d-flex justify-content-between">
          <div><i class="bi-person"></i> <small>Admin</small></div>
          <div><i class="bi-chat-left"></i> <small>0 Komentar</small></div>
        </div>
        <i class="bi-calendar"></i> <small>{{ published }}</small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardBerita",
  props: ["title", "published", "thumbnail", "slug"],
  methods: {
    handleClick() {
      this.$router.push(`/berita/${this.slug}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variable.scss";
.card {
  &:hover {
    cursor: pointer;
  }
  border: 1px solid #dfdfdf;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  h5.card-title {
    font-weight: 600 !important;
    font-size: 1.2em;
  }
  .card-text {
    font-weight: 400;
    color: $textColor;
  }
}
</style>
